export const SETTINGS_LIST_LOADING = "SETTINGS_LIST_LOADING";
export const SETTINGS_LIST_FAILURE = "SETTINGS_LIST_FAILURE";
export const SETTINGS_LIST_SUCCESS = "SETTINGS_LIST_SUCCESS";

export type SettingsType = {
  vehicles: Array<Vehicles>;
};

export type Vehicles = {
  VIN: string;
  DCM: string;
  AssetNumber: string;
  DCMFirmwareVersion: string;
  MCUFirmwareVersion: string;
};

export interface SettingsListLoading {
  type: typeof SETTINGS_LIST_LOADING;
}

export interface SettingsListFailure {
  type: typeof SETTINGS_LIST_FAILURE;
}

export interface SettingsListSuccess {
  type: typeof SETTINGS_LIST_SUCCESS;
  payload: Array<Vehicles>;
}

export type SettingsListDispatchTypes =
  | SettingsListLoading
  | SettingsListFailure
  | SettingsListSuccess;
